.nav {
    .menu & {
        background-color: $brand-primary;
        @include margin(0);
        @include padding(0);

        > li {
            float: none;
            padding-top: 0;
            width: auto;

            > a {
                background-color: transparent;
                background-position:  50% 30px;
                background-size: auto 35px;
                border-radius: 0;
                border-bottom: 1px solid $c-brand-primary-hover;
                border-left: 4px solid transparent;
                color: $c-side-menu;
                text-align: center;
                @include box-shadow(none);
                @include padding(75px 20px 15px 20px);
                @include position(static, null null null null);

                @media (max-height: $h-media-md) {
                    background-position:  50% 15px;
                    font-size: 17px;
                    @include padding(55px null 10px null);
                }

                &:hover,
                &:active,
                &:active:hover,
                &:focus {
                    background-color: $c-brand-primary-hover;
                    border-left-color: #fff;
                }
            }

            &.active > a,
            > a.active {
                background-color: $c-brand-primary-hover;
                border-left: 4px solid #fff;
            }

            &.menu__item--partitions > a {
                background-image: url('#{$images}/svg/icon-menu-partitions-small.svg');
            }

            &.menu__item--events > a {
                background-image: url('#{$images}/svg/icon_sidemenu-all-events.svg');
            }

            &.menu__item--sensors > a {
                background-image: url('#{$images}/svg/icon_sidemenu-sensors.svg');
            }

            &.menu__item--outputs > a {
                background-image: url('#{$images}/svg/icon-menu-outputs-small.svg');
            }

            &.menu__item--react > a {
                background-image: url('#{$images}/svg/icon-menu-react.svg');
            }

            &.menu__item--settings > a {
                background-image: url('#{$images}/svg/icon-menu-settings-small.svg');
            }

            &.menu__item--reactions > a {
                background-image: url('#{$images}/svg/icon-react.svg');
            }
            
            &.menu__item--cameras > a {
                background-image: url('#{$images}/svg/icon-menu-video-small.svg');
            }
        }
    }

    .user-menu & {
        > li {
            > a {
                text-align: center;
                width: 74px;
                @include padding(25px 10px 24px 10px);

                @media (max-width: $screen-lg-max) {
                    width: 55px;
                }

                &:hover,
                &:focus {
                    background-color: transparent;
                    color: $c-side-menu-icon-desktop;
                    @include opacity(0.8);
                }

                &:after {
                    display: none;
                }
            }

            &.open > a {
                &,
                &:hover,
                &:focus {
                    border-color: #d9d9da;
                }
            }
        }
    }
}
