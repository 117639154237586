.keypad {
    &__key {
        .btn {
            font-size: $fz-keypad-key-desktop;
        }
    }
}

#keypadPopup {
    background-color: transparent;

    .popup {
        &__overlay {
            background-color: $bg-popup-overlay;
            @include position(absolute, 0 0 0 0);
        }

        &__content {
            background-color: $bg-popup;
            border-radius: $border-radius-base;
            //bottom: auto;
            //height: auto;
            //max-height: 90%;
            max-width: 450px;
            min-width: 305px;
            //right: auto;
            //transform: translateY(-50%) translateX(-50%);
            width: 85%;
            @include padding(30px 20px 10px);
            //@include position(absolute, 50% null null 50%);

            @media (max-height: $wp-keypad-sm) {
                max-width: 350px;
                @include padding(25px 20px 10px);
            }

            @media (max-height: $wp-keypad-xs) {
                max-width: 300px;
            }
        }
    }
}
