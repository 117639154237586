.dropdown-menu {
    border: 0;
    border-radius: 0 0 4px 4px;
    overflow: hidden;
    @include box-shadow($bxsh-content-desktop);
    @include margin(0 0 0 0);
    @include padding(0);

    &--right {
        left: auto;
        right: 0;
    }

    li {
        border-bottom: 1px solid $body-bg;
        float: none;

        a {
            color: $brand-primary;
            @include padding(10px 20px);
        }
    }
}
