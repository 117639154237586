.frame {
    max-height: 100%;

    @media (max-width: $screen-xs-max) {
        padding-top: 0;
    }

    &__content {
        @include padding($p-auth-desktop 0);

        @media (max-height: 600px) {
            @include padding(($p-auth-desktop / 2) 0);
        }
    }
}
