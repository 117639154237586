.list {
    @include margin(-15px null);

    .errors + & {
        margin-top: 0;
    }

    .#{$pre}table {
        &__cell {
            &.list {
                &__icon {
                    padding-left: 25px;
                }
            }
        }
    }

    &__sub-title {

        &--limited {
            max-width: 100%;
        }
    }

    &--areas {
        @include margin(0 -5px);

        ul {
            margin-bottom: 0;
        }
    }

    &__areas-item {
        width: 180px;
        @include padding(null 15px);
    }

    &__action {
        white-space: nowrap;
    }

    &__top-title {
        .list + & {
            margin-top: 30px;
        }
    }
}

.event-disabled {
    background-color: #EEE;
}