.auth:not(form) {
    background-color: transparent;
    background-image: url('#{$images}/bg-auth-desktop.jpg');
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    position: relative;
    overflow: hidden;

    .frame {
        background-color: $bg-auth;
        border-radius: $border-radius-base;
        min-height: 0;
        margin-left: (-$w-auth-desktop / 2);
        max-height: 98%;
        overflow-x: hidden;
        overflow-y: auto;
        width: $w-auth-desktop;
        @include padding(0 $p-auth-desktop);
        @include position(absolute, 50% null null 50%);
        @include transform(translateY(-50%));

        @media (max-height: 700px) {
            &__content {
                @include padding(30px 0);
            }
        }
    }

    .form-group {
        margin-bottom: $form-group-margin-bottom-auth-desktop;

        @media (max-height: 600px) {
            margin-bottom: $form-group-margin-bottom-auth-desktop-sm;
        }
    }

    .form-control {
        height: $input-height-base-desktop;
    }

    .btn {
        font-size: $fz-input-desktop;
        height: auto;
        @include padding(8px null);
    }
}
