.carousel {
    min-height: 580px;
    max-width: 610px;
    @include margin(0 auto);

    .help & {
        @include position(relative, null null null null);
    }

    @media (max-height: 780px) {
        min-height: 400px;

        &__leaf-text {
            height: 68%;
            top: 11%;
        }

        &__leaf-icon {
            @include padding(30px null 0 25px);

            svg {
                @include size(auto, 100px);
            }
        }
    }

    &__leaf-navigation {
        &--static {
            margin-top: 40px;
        }

        .content & {
            @include padding(null 30px);
        }
    }

    &__leaf-image {
        padding-top: 10px;

        @media (max-height: 780px) {
            padding-top: 5px;

            img {
                max-height: 130px;
            }
        }
    }

    &__leaf--no-system {
        @include position(static, null);

        .carousel {
            &__leaf-text {
                @include position(static, null);
            }
        }

        + .carousel {
            &__leaf-navigation {
                @include padding(30px 30px 10px);
                @include position(static, null);
            }
        }
    }
}
